.login-body {
    background-color: white;
    // height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-wrapper {
    color:black;
    width: 100%;
    min-width: 300px; 
    padding: 30px;
    background-color: #f5f5f5;
    border-radius: 8px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; 

  }
  
  .img-fluid {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
  
  h3 {
    margin: 10px 0;
    text-align: center; 
  }
  
  h4 {
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .w-100 {
    width: 100%;
  }
  
  .ma-danger,
  .p-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: center; 
  }
  
  .text-warning {
    color: orange;
    margin-top: 10px;
    text-align: center; 
  }
  
  .text-center {
    text-align: center;
  }
  
  .input-wrapper {
    min-width: 300px;
    // width:'100%';
    margin-bottom: 15px;
    display:flex;
    flex-direction: column;
    // align-items: center;
  }
  
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center; 
    margin-top: 30px;
  }
  
  @media (max-width: 768px) {
    .login-wrapper {
      max-width: 90%;
    }
  }
  