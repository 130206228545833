.exam-correction {

    .p-marks {

        font-weight: 600;
        font-size: 20px;
        font-family: Poppins;
    }
}


.scoring-preview {

    .sc-title {
        color: #076EFF;
        font: normal normal normal 16px/24px InterSemiBold !important;
        font-weight: 500;
        text-align: start;

    }

    .sc-name {
        font: normal normal normal 16px/24px InterRegular !important;
        font-weight: 500;
        color: #4d4d4d;
        text-transform: capitalize;
    }

    .sc-marks {
        padding: 5px 10px;
        // margin-left: 30px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        height: 36px;
        border-radius: 7px;
        width: 80px;
        font: normal normal normal 16px/24px InterRegular !important;
        font-weight: 500;
        text-align: center;
    }

    .sc-outof {
        margin-left: 5px;
        margin-top: 6px;
        //font-weight: bold;
        font: normal normal normal 16px/24px InterRegular !important;
        font-weight: 500;
    }

    .q-unmarked {
        color: var(--primary-color);
    }


    .sc-mark-c {
        margin: 6px;

    }

    .sc-mark-l {
        margin-top: 4px;
        width: 70px;
    }

    .sc-mark-total {
        margin-top: 4px;
        width: 120px;
    }
}


.ma-cr-pagin {
    margin-top: 16px;

    .cr-pagi-l {
        padding: 2px 5px;
        font-size: 16px;
        cursor: pointer;
        font-weight: bold;
    }

    .cr-pagi-r {
        padding: 2px 10px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }

    .cr-pagi-v {
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 6px 12px;
        // border-radius: 10px;

    }
}


.tui-colorpicker-clearfix {

    overflow: auto;

    .tui-colorpicker-palette-button {

        height: 20px;
        width: 20px;
    }



}


li[tooltip-content="Delete"] {
    display: none !important;
}

li[tooltip-content="Reset"] {
    display: none !important;
}

li[tooltip-content="DeleteAll"] {
    display: none !important;
}


li[tooltip-content="History"] {
    display: none !important;
}

li[tooltip-content="Undo"] {
    display: none !important;
}

li[tooltip-content="Redo"] {
    display: none !important;
}

.student-comments {
    font: normal normal normal 16px/14px InterRegular !important;
    font-weight: 500;
}


.tui-image-editor-item .tui-image-editor-icpartition {
    display: none !important;

}

.addStart {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 8px !important;



    border: 1px solid #076EFF;
    background: #076EFF;
    margin: 2px;

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);


}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    /* Two columns with equal width */
    gap: 5px;
    /* Optional: Adds space between columns */
}

.outlinedCancel {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    border-radius: 8px !important;
    margin: 2px;
    background: transparent;
    /* No solid background */
    color: #076EFF;
    /* Text color should match the primary color */
    border: 1px solid #076EFF;
    /* Border to create the outlined effect */

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
}

.outlinedButtonttt:hover {
    background: white;
    /* Solid background on hover */
    color: white;
    /* White text on hover for contrast */
    border-color: #076EFF;
    /* Maintain border color */
}

.change-toolbar {
    background: #FFFFFF;
    border: 1px solid #dee2e6;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 3px 6px var(--shadow-65);
}

.iconColor {
    background-color: transparent;
    color: #076EFF;
    border-color: transparent;
  }