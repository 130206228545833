.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;
    font-weight: 400;
    font-size: 24px;

}

.addButton {
    font: normal normal normal 16px/24px InterSemiBold !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 12px;
    border-style:none;
    border-width: none;
}
.createtest-card{
padding:40px;
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px -4px rgba(0, 0, 0, 0.25);
border-radius: 12px;
margin-bottom:20px;
}

#time24 .p-inputtext{
    border: none;
}










